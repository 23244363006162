/**
 * This file contains locale strings which were extracted from the SDK `locale` module
 * (e.g., because they had customer-specific terms in them), as well as any future
 * locale strings needed only at the B2B level.
 */

export const strings = {
  default: {
    DOMAINS: 'Domains',
    DOMAINS_COUNT: '{count} domains',
    PC_HEALTH_LOGO: 'PC Health logo',
    PC_HEALTH_ID: 'PC Health ID',
    MAPLE_USER_TYPE: 'Maple User Type',
    MAPLE_CONSENT_DATE: 'Maple Consent Date',
    MAPLE_UNLINK_DATE: 'Maple Unlink Date',
    NO_MAPLE_ACCOUNT_LINKED: 'No Maple account linked',
    SDM_MAPLE_USER:
      'SDM user - this user was first introduced to Maple through PC Health',
    SHARED_MAPLE_USER:
      'Shared user - this user linked their existing Maple account to PC Health',
    CURRENT_MAPLE_USER_STATUS: 'Current Maple User Status',
    UNLINKED_MAPLE_ACCOUNT_OPTIONS:
      'Should the member wish to access Maple through the PC Health handoff flow, please share the following options:',
    UNLINKED_MAPLE_ACCOUNT_OPTION_ONE:
      '(Existing Maple User) Use existing Maple account credentials within the PC Health handoff flow to access Maple, and link their PC Health account.',
    UNLINKED_MAPLE_ACCOUNT_OPTION_TWO:
      '(New Maple User) Create a new Maple account through the PC Health handoff flow to link their PC Health account.',
    MAPLE_USER_PROFILE: 'Maple User Profile',
    KPMG_LIFE_INSURANCE_WELLNESS_DOLLARS_1_UPDATED_2025:
      'KPMG\u2019s fully firm-funded mental health benefit is $3,000 per benefit year. KPMG continues the commitment to cover the full cost of this benefit on your behalf.',
  },
  es: {
    PC_HEALTH_ID: 'ID de PC Health',
    PC_HEALTH_LOGO: 'Logo de PC Salud',
    MAPLE_USER_PROFILE: 'Perfil del usuario de Maple',
    UNLINKED_MAPLE_ACCOUNT_OPTION_TWO:
      '(Usuario nuevo de Maple) Crea una cuenta nueva de Maple a través del flujo de transferencia de PC Health para vincular tu cuenta de PC Health.',
    UNLINKED_MAPLE_ACCOUNT_OPTION_ONE:
      '(Usuario actual de Maple) Utiliza las credenciales de tu cuenta existente de Maple dentro del flujo de transferencia de PC Health para acceder a Maple y vincular tu cuenta de PC Health.',
    UNLINKED_MAPLE_ACCOUNT_OPTIONS:
      'Si el miembro desea acceder a Maple a través del flujo de transferencia de PC Health, comparte las siguientes opciones:',
    CURRENT_MAPLE_USER_STATUS: 'Estatus actual de usuario de Maple',
    SHARED_MAPLE_USER:
      'Usuario compartido: este usuario vinculó su cuenta existente de Maple a PC Health',
    SDM_MAPLE_USER:
      'Usuario de SDM: este usuario se introdujo por primera vez a Maple a través de PC Health',
    NO_MAPLE_ACCOUNT_LINKED: 'No hay ninguna cuenta de Maple vinculada',
    MAPLE_UNLINK_DATE: 'Fecha de desvinculación de Maple',
    MAPLE_CONSENT_DATE: 'Fecha de consentimiento a Maple',
    MAPLE_USER_TYPE: 'Tipo de usuario de Maple',
  },
  fr: {
    PC_HEALTH_ID: 'ID PC Santé',
    PC_HEALTH_LOGO: 'Logo PC Santé',
    MAPLE_USER_PROFILE: "Profil de l'utilisateur Maple",
    UNLINKED_MAPLE_ACCOUNT_OPTION_TWO:
      '(Nouvel utilisateur Maple) Créez un nouveau compte Maple via le flux de transfert PC Santé pour lier leur compte PC Santé.',
    UNLINKED_MAPLE_ACCOUNT_OPTION_ONE:
      "(Utilisateur Maple préexistant) Utilisez les informations d'identification de compte Maple préexistantes dans le flux de transfert PC Santé pour accéder à Maple et liez leur compte PC Santé.",
    UNLINKED_MAPLE_ACCOUNT_OPTIONS:
      'Si le membre souhaite accéder à Maple via le flux de transfert PC Santé, veuillez partager les options suivantes:',
    CURRENT_MAPLE_USER_STATUS: "Statut actuel d'utilisateur Maple",
    SHARED_MAPLE_USER:
      'Utilisateur partagé - cet utilisateur a associé son compte Maple préexistant à PC Santé',
    SDM_MAPLE_USER:
      'Utilisateur Pharmaprix - cet utilisateur a été introduit pour la première fois à Maple via PC Santé',
    NO_MAPLE_ACCOUNT_LINKED: 'Aucun compte Maple associé',
    MAPLE_UNLINK_DATE: 'Date de dissociation Maple',
    MAPLE_CONSENT_DATE: 'Date de consentement Maple',
    MAPLE_USER_TYPE: "Type d'utilisateur Maple",
    KPMG_LIFE_INSURANCE_WELLNESS_DOLLARS_1_UPDATED_2025:
      "Les avantages sociaux en matière de santé mentale entièrement financée par KPMG s'élèvent à 3•000•$ par année du régime. KPMG poursuit son engagement à couvrir le coût total de cet avantage en votre nom.",
    KPMG_LIFE_INSURANCE_WELLNESS_DOLLARS_3:
      "Même si nous ne pouvons pas contrôler ce qui se passe dans l'ensemble du marché, afin de compenser une partie de cette augmentation des prix, nous avons investi plus d'argent dans la subvention de la réserve pour le bien-être de la couverture pour couples et familiale.",
    KPMG_PAYS: 'KPMG paye',
    WELLNESS_POOL: 'réserve pour le bien-être',
    WELLNESS_POOL_CAPITALIZED: 'Réserve pour le bien-être',
    WELLNESS_POOL_DESCRIPTION:
      'KPMG a conçu pour vous un régime de rémunération globale largement subventionné au moyen d’une allocation appelée réserve bien-être. Vous répartissez les fonds qui constituent cette réserve comme bon vous semble, dans les avantages sociaux à la carte ou ailleurs. Vous faites vos choix en fonction de votre style de vie, et le solde change sous vos yeux.',
    BENEFIT_SUBSIDIZATION: 'Subvention des avantages',
    HAVE_QUESTIONS: 'Avez-vous des questions?',
    YOU_CAN_ALSO_VISIT_KNOWLEDGE_BASE:
      'Vous pouvez également consulter {knowledgeBaseLink} de connaissances pour en savoir plus sur les avantages offerts.',
    SAVING_AND_EDITING: 'Enregistrement et modification',
    POLICY_DURATION: 'Durée de la police',
    POLICY_DURATION_DESCRIPTION:
      "Vos choix effectués durant la période d'inscription prendront effet le 1 du mois suivant l'inscription au régime d'avantages sociaux. Ils seront valables jusqu'au <strong> {planEndDate} </strong>.",
    NEW_HIRE_POLICY_DURATION_DESCRIPTION:
      "Vos choix effectués durant la période d'inscription prendront effet le 1 du mois suivant l'inscription au régime d'avantages sociaux. Ils seront valables jusqu'au <strong> {planEndDate} </strong>.",
    ENROLLMENT_PERIOD: "Période d'inscription",
    ENROLLMENT_PERIOD_DESCRIPTION:
      "Vous pouvez revoir et mettre à jour vos choix à votre guise jusqu'à la fin de la période d'inscription ({enrollmentEndDate}). Vous pouvez également modifier les sélections de votre régime à tout moment de l’année en communiquant avec l’équipe du service à la clientèle de League. Les avantages entreront en vigueur le premier du mois suivant la soumission.",
    PAYROLL_DEDUCTIONS: 'Retenues sur la paie',
    WELCOME_TO_YOUR_BENEFITS_EXPERIENCE:
      "Bienvenue à votre programme d'avantages",
    WELCOME_SCREEN_SUB_HEAD_DESCRIPTION:
      "KPMG a mis à votre disposition un programme d'avantages complet spécialement pour vous. Au cours de ce processus d'inscription, vous choisirez parmi divers avantages pour créer un plan flexible adapté à vos besoins. Nous vous guiderons à chaque étape du processus.",
    PAYROLL_DEDUCTIONS_PARTNERS:
      'Cette valeur indique le montant couvert que vous devrez débourser. <p> Le coût des avantages sociaux obligatoires (ILD, assurance-vie et assurance-accidents) sera déjà affiché lorsque vous vous connecterez. </p><p> Le montant Vous payez changera en fonction de vos choix. </p>.',
    SAVING_AND_EDITING_DESCRIPTION:
      "Vous souhaiterez peut-être prendre votre temps pour terminer l'inscription. Ne vous inquiétez pas, vos sélections sont enregistrées au fur et à mesure que vous parcourez chaque section, mais vous devez les soumettre pour qu’elles prennent effet.",
    SAVING_AND_EDITING_DESCRIPTION_MULTIPLE_PLANS:
      "Vous souhaiterez peut-être prendre votre temps pour terminer l'inscription. Ne vous inquiétez pas, vos sélections sont enregistrées au fur et à mesure que vous parcourez chaque section, mais vous devez les soumettre pour qu’elles prennent effet.",
    PARTNER_SAVING_AND_EDITING_DESCRIPTION:
      "Vous souhaiterez peut-être prendre votre temps pour terminer l'inscription. Ne vous inquiétez pas, vos sélections sont enregistrées au fur et à mesure que vous parcourez chaque section, mais vous devez les soumettre pour qu’elles prennent effet.",
    NEW_HIRE_SAVING_AND_EDITING_DESCRIPTION:
      "Vous souhaiterez peut-être prendre votre temps pour terminer l'inscription. Ne vous inquiétez pas, vos sélections sont enregistrées au fur et à mesure que vous parcourez chaque section, mais vous devez les soumettre pour qu’elles prennent effet.",
    READY_TO_BUILD_YOUR_PLAN: 'Prêt à élaborer ton plan?',
  },
};
